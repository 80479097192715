export default {
  siteTitle: 'Get bonus',
  blogerName: 'ROBERT JOGA',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@candidoslotbr'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/eurobertcandd/'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c2ab63399',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irit.com/c89459465',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/cee06b8ed',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c4cf9a3a8',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cdbcce36e?activation_code=CDC23',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c64cb28eb',
      gameTitle: 'Jet Air',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cc7fca4d5',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/cc468a56e',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/ce2428db5',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c9febfadd',
      gameTitle: 'Doors Of Fresh',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>CDC23</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CDC23',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
